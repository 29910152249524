import Video from "./components/video/Video";
import './App.scss';
import matchymatchy from "./assets/matchymatchy.png";
import bitta from "./assets/bitta.png";
import recordsapp from "./assets/RECORDS.svg";
import externallink from "./assets/external-link.svg";
import { Component } from "react";

class App extends Component {

  state = {
    toggleShowVideo: false,
  }

  openModal = () => {
    this.setState({
        toggleShowVideo: true,
    });
  };

  closeModal = () => {
    this.setState({
        toggleShowVideo: false
    });
  };

  render() {

    return (
      <div className="portfolio">
        <header className="portfolio__header">
          <div className="portfolio__header__left">
            <h1 className="portfolio__header__left--static">Anthony Henley</h1>
          </div>
          <div className="portfolio__header__right">
            <h1 className="portfolio__header__right--dynamic">is a Full Stack Web Developer in Ottawa</h1>
          </div>
        </header>
        <section className="portfolio__contents">
          <div className="portfolio__contents__contact--mobile">
              <a href="https://www.linkedin.com/in/anthonyhenley" target="_blank" rel="noreferrer">
                <div className="portfolio__contents__contact--social">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
                </div>
              </a>
              <a href="https://github.com/anthonyhenley" target="_blank" rel="noreferrer">
                <div className="portfolio__contents__contact--social">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
                </div>
              </a>
            </div>
          <a href="https://bitta.work/" target="_blank" rel="noreferrer">
            <div className="portfolio__contents__project">
              <div className="portfolio__contents__project__title"><img className="portfolio__contents__project__title__logo" src={bitta} alt="bitta"/></div>
              <h2 className="portfolio__contents__project__description">Finish tasks in small timed bursts, get a reward</h2>
              <h2 className="portfolio__contents__project__description--link">
                click to visit bitta 
                <img className="portfolio__contents__project__description--icon" src={externallink} alt="go to bitta.work"/>
              </h2>
              <div className="portfolio__contents__project__techstack">
                <div className="portfolio__contents__project__techstack__logo">
                  <img className="portfolio__contents__project__techstack__logo--angular" src="https://angular.io/assets/images/logos/angular/angular.svg" alt="Angular"/>
                </div>
                <div className="portfolio__contents__project__techstack__logo">
                  <img className="portfolio__contents__project__techstack__logo--firebase" src="https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg" alt="Firebase"/>
                </div>
              </div>
            </div>
          </a>
          <div className="portfolio__contents__project" onClick={this.openModal}>
            <div className="portfolio__contents__project__title"><img className="portfolio__contents__project__title__logo" src={matchymatchy} alt="matchymatchy app"/></div>
            <h2 className="portfolio__contents__project__description">Modern, connected bank reconciliation software</h2>
            <h2 className="portfolio__contents__project__description--link">
                click to watch demo
                <img className="portfolio__contents__project__description--icon" src={externallink} alt="see matchymatchy in action"/>
            </h2>
            <div className="portfolio__contents__project__techstack">
              <div className="portfolio__contents__project__techstack__logo">
                <img className="portfolio__contents__project__techstack__logo--react" src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="React"/>
              </div>
              <div className="portfolio__contents__project__techstack__logo">
                <img className="portfolio__contents__project__techstack__logo--plaid" src="https://upload.wikimedia.org/wikipedia/commons/c/c0/Plaid_logo.svg" alt="Plaid"/>
              </div>
            </div>
          </div>
          <a href="https://recordsapp.netlify.app/" target="_blank" rel="noreferrer">
            <div className="portfolio__contents__project">
              <div className="portfolio__contents__project__title"><img className="portfolio__contents__project__title__logo--records" src={recordsapp} alt="RECORDS app"/></div>
                <div>
                  <h2 className="portfolio__contents__project__description--hackathon">BrainStation x EY Hackathon</h2>
                  <h2 className="portfolio__contents__project__description">NFT marketplace for live entertainment industry</h2>
                </div>
                <h2 className="portfolio__contents__project__description--link">
                  click to visit prototype
                  <img className="portfolio__contents__project__description--icon" src={externallink} alt="go to RECORDS"/>
                </h2>
                <div className="portfolio__contents__project__techstack">
                  <div className="portfolio__contents__project__techstack__logo">
                    <img className="portfolio__contents__project__techstack__logo--react" src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="React"/>
                  </div>
                  <div className="portfolio__contents__project__techstack__logo">
                    <img className="portfolio__contents__project__techstack__logo--sass" src="https://upload.wikimedia.org/wikipedia/commons/9/96/Sass_Logo_Color.svg" alt="Sass"/>
                  </div>
                </div>
            </div>
          </a>
          <div className="portfolio__contents__contact--desktop">
            <a href="https://www.linkedin.com/in/anthonyhenley" target="_blank" rel="noreferrer">
              <div className="portfolio__contents__contact--social">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
              </div>
            </a>
            <a href="https://github.com/anthonyhenley" target="_blank" rel="noreferrer">
              <div className="portfolio__contents__contact--social">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
              </div>
            </a>
          </div>
          {this.state.toggleShowVideo ? (<Video closeModal={this.closeModal}/>) : null}
          <ul className='circles'>
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>=</li>
            <li>/</li>
            <li>5</li>
            <li>6</li>
            <li>7</li>
            <li>+</li>
            <li>8</li>
            <li>9</li>
          </ul>
        </section>
        <footer className="portfolio__footer">
        </footer>
      </div>
    );
  }
}

export default App;

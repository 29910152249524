import React from "react";

function Video(props) {

    return (
        <section className="video">
            <div className="video__modal">
                <div className="video__modal__close">
                    <button onClick={props.closeModal} className="video__modal__button--close">x</button>
                </div>
                <iframe width="80%" height="80%" className="video__modal__content" src="https://www.loom.com/embed/a8fe1350e7d54a6ca38e7bcd08c27840" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
        </section>
    )
}

export default Video;